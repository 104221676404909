import $ from '@rover/globals/jquery';
import dataLayer from '@rover/globals/dataLayer';

import Alerts from '@rover/utilities/Alerts';
import { initIntlPhoneInput } from '@rover/react-lib/src/components/formFields/IntlPhoneInput/IntlPhoneInputUtils';

export default class AppInstallSMSForm {
  static async init($el) {
    const { pluginInstance: intlTelInput } = await initIntlPhoneInput(
      document.getElementsByClassName('js-send-to-phone-phone-number')[0],
      {
        countryCode: Rover.context.countryCode,
        locale: Rover.context.language,
        useHiddenInput: true,
      }
    );

    $el.autoDisableAjaxForm({
      beforeSerialize() {
        $('input[name=phone_number]').val(intlTelInput.getNumber());
      },
      error: (xhr, statusText, httpStatusText, $form) => {
        // Clear out any other alert containers
        $('.js-send-to-phone-message-container')
          .children()
          .remove();
        Alerts.showToast({
          severity: 'danger',
          message: xhr.responseJSON.message,
          container: '.js-send-to-phone-message-container',
        });
        dataLayer.push({
          event: 'owner-events-app-download-text-me-a-link',
          submitStatus: 'fail',
          errorMessage: xhr.responseJSON.message,
        });
      },
      success: (response, statusText, xhr, $form) => {
        // Clear out any other alert containers
        $('.js-send-to-phone-message-container')
          .children()
          .remove();
        intlTelInput.setNumber('');
        Alerts.showToast({
          severity: 'success',
          message: xhr.responseJSON.message,
          container: '.js-send-to-phone-message-container',
        });
        dataLayer.push({
          event: 'owner-events-app-download-text-me-a-link',
          submitStatus: 'success',
          errorMessage: null,
        });
        $form.trigger('message-sent');
      },
    });
  }
}
